import React, {useState, useEffect, useCallback} from 'react';
import { InlineWidget } from 'react-calendly';
import TagManager from 'react-gtm-module/dist/TagManager';
// import { Link } from 'react-router-dom';

export default function ProjectCTA() {
  const [isActive, setIsActive] = useState(false);
  const toggle = () => setIsActive(!isActive);
  useEffect(() => {
    if(isActive) {
          document.querySelector("body").style.overflow = 'hidden';
      } else {
          document.querySelector('body').removeAttribute("style");
      }
  }, [isActive]);

  const [isModalActive, setIsModalActive] = useState(false);
  const toggleModal = () => setIsModalActive(!isModalActive);
  useEffect(() => {
    if(isModalActive) {
          document.querySelector("body").style.overflow = 'hidden';
      } else {
          document.querySelector('body').removeAttribute("style");
      }
  }, [isModalActive]);

  const escFunction2 = useCallback((event) => {
    if (event.key === "Escape") {
      if(isActive)
        setIsActive(!isActive);
      if(isModalActive)
        setIsModalActive(!isModalActive);
    }
  }, [isActive, isModalActive]);

  useEffect(() => {
    document.addEventListener("keydown", escFunction2, false);

    return () => {
      document.removeEventListener("keydown", escFunction2, false);
    };
  }, [escFunction2]);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [msg, setMsg] = useState('');
  const [submitButtonText, setSubmitButtonText] = useState('Odoslať');
  const [formSucc, setFormSucc] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    const inputs = { name, email, tel, msg };
    setSubmitButtonText('Odosiela sa...');
    fetch('https://be.siedwebs.sk/api/send-cta', {
      method: 'POST',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(inputs)
    }).then((response) => {
      setSubmitButtonText('Odoslať');
      setIsActive(false);
      setName('');
      setEmail('');
      setTel('');
      setMsg('');
      if(response.ok) {
        setFormSucc(true);
      } else {
        setFormSucc(false);
      }
    })
    fetch('https://be.siedwebs.sk/api/send-cta-reply', {
      method: 'POST',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(inputs)
    }).then((response) => {
      setSubmitButtonText('Odoslať');
      setIsActive(false);
      setName('');
      setEmail('');
      setTel('');
      setMsg('');
      if(response.ok) {
        setFormSucc(true);
      } else {
        setFormSucc(false);
      }
      setIsModalActive(true);
    })
    TagManager.initialize({'gtmId': 'GTM-KWNVL8X'})
    const tagManagerArgs = {
      dataLayer: {
          'js': new Date(), // current date time,
          event: 'ctaConsultation',
      }
    }
    TagManager.dataLayer(tagManagerArgs);
  }
  return <>
        {/*=====================================*/}
        {/*=     Call To Action Area Start     =*/}
        {/*=====================================*/}
        <section className="section call-to-action-area">
          <div className="container">
            <div className="call-to-action">
              <div className="section-heading heading-light">
                <span className="subtitle">Lassen Sie uns etwas aufbauen – zusammen</span>
                <h2 className="title">Sind Sie bereit für größere Erfolge?</h2>
                <span onClick={toggle} className="axil-btn btn-large btn-fill-dark-blue">Demo-Anruf buchen</span>
              </div>
              <div className="thumbnail">
                <div className="larg-thumb" data-sal="zoom-in" data-sal-duration={600} data-sal-delay={100}>
                  <img loading="lazy" height="" width=""className="" src="assets/media/others/chat-group.png" alt="Chat" title='Chat' />
                </div>
                <ul className="list-unstyled small-thumb">
                  <li className="shape shape-1" data-sal="slide-right" data-sal-duration={800} data-sal-delay={400}>
                    <img loading="lazy" height="" width=""className="" src="assets/media/others/laptop-poses.png" alt="Hannah" title='Hannah' />
                  </li>
                  <li className="shape shape-2" data-sal="slide-left" data-sal-duration={800} data-sal-delay={300}>
                    <img loading="lazy" height="" width=""className="" src="assets/media/others/bill-pay.png" alt="Bill" title='Bill' />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <ul className="list-unstyled shape-group-9">
            <li className="shape shape-1"><img height="" width=""src="assets/media/others/bubble-12.png" alt="Comments" /></li>
            <li className="shape shape-2"><img height="" width=""src="assets/media/others/bubble-16.png" alt="Comments" /></li>
            <li className="shape shape-3"><img height="" width=""src="assets/media/others/bubble-13.png" alt="Comments" /></li>
            <li className="shape shape-4"><img height="" width=""src="assets/media/others/bubble-14.png" alt="Comments" /></li>
            <li className="shape shape-5"><img height="" width=""src="assets/media/others/bubble-16.png" alt="Comments" /></li>
            <li className="shape shape-6"><img height="" width=""src="assets/media/others/bubble-15.png" alt="Comments" /></li>
            <li className="shape shape-7"><img height="" width=""src="assets/media/others/bubble-16.png" alt="Comments" /></li>
          </ul> */}
        </section>
        <div className={`pric-form`}>
                        <div className={`form-div ${isActive ? "show" : ""}`}>
                            <div onClick={toggle} className={`pric-form-close ${isActive ? "show" : ""}`}><button aria-label="Zatvoriť fomulár"><i className="fas fa-times" /></button></div>
                            {/* <form onSubmit={handleSubmit} className="axil-contact-form">
                                    <div className="form-group">
                                        <label for="contact-name">Meno alebo Firma *</label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        name="contact-name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="contact-email">Email *</label>
                                        <input
                                        type="email"
                                        className="form-control"
                                        name="contact-email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="contact-tel">Mobil *</label>
                                        <input
                                        type="tel"
                                        className="form-control"
                                        name="contact-tel"
                                        value={tel}
                                        onChange={(e) => setTel(e.target.value)}
                                        required
                                        />
                                    </div>
                                    <div className="form-group mb--40">
                                        <label for="contact-message">Špecifikácie (voliteľné)</label>
                                        <textarea
                                        name="contact-message"
                                        id="contact-message"
                                        className="form-control textarea"
                                        cols={30}
                                        rows={4}
                                        value={msg}
                                        onChange={(e) => setMsg(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button
                                        type="submit"
                                        className="axil-btn btn-fill-primary btn-fluid btn-primary"
                                        name="submit-btn"
                                        aria-label="Odoslať formulár"
                                        >
                                        {submitButtonText}
                                        </button>
                                    </div>
                            </form> */}
                            <InlineWidget url="https://calendly.com/hannahsied/initiales-telefonat-buchen?hide_gdpr_banner=1&primary_color=acc507" />
                        </div>
                        <div onClick={toggle} className={`backdrop fade ${isActive ? "show" : ""}`}></div>
                    </div>

                    <div className={`form-modal`}>
                      <div className={`form-modal-body ${isModalActive ? "show" : ""}`}>
                        {formSucc ? 
                          <>
                            <i class="fa fa-check succ"></i>
                            <h3>Odoslané!</h3>
                            <p>Ďakujeme Vám za dôveru.</p>
                          </>
                          :
                          <>
                            <i class="fa fa-times fail"></i>
                            <h3 class="text-center">Nastala<br />Chyba!</h3>
                            <p>Prosím skúste znovu...</p>
                          </>
                        }
                        
                        <span onClick={toggleModal} className='axil-btn btn-fill-primary btn-primary'>Zavrieť</span>
                      </div>
                      <div onClick={toggleModal} className={`backdrop fade ${isModalActive ? "show" : ""}`}></div>
                    </div>
  </>;
}
