import React from 'react';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default function Reviews() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 7
    },
    desktop: {
      breakpoint: { max: 3000, min: 1224 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1224, min: 767 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
    }
  };

  return <>
          <div className="container">
            <div className="section-heading heading-left">
              <span className="subtitle">Reviews & Feedback</span>
              <h2 className="title">Unsere Kunden sprechen für sich selbst ...</h2>
              <p>Um eine herausragende Zusammenarbeit garantieren zu können, geht es bei uns um mehr als „nur“ Resultate.</p>
            </div>
          </div>
            <div className="row d-none d-sm-block">
              <Carousel 
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                // autoPlaySpeed={3000}
                autoPlaySpeed={1}
                keyBoardControl
                additionalTransfrom={0}
                customTransition="all 7s linear"
                arrows={false}
                swipeable
                pauseOnHover={false}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                transitionDuration={7000}
                >
              <div className="col-11 h-100 px-3">
                <div className="testimonial-grid ">
                  <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/ukrpass.png" alt="ukrpass" /></span>
                  <p>“ Perfekte Zusammenarbeit – exakt das, was wir erwartet haben. ”</p>
                  <div className="author-info">
                    <div className="thumb">
                      <img loading="lazy" height="" width="50"src="assets/media/sied/davidcantor.png" alt="David Cantor" />
                    </div>
                    <div className="content">
                      <span className="name">David Cantor</span>
                      <span className="designation">Gründer &amp; CEO @ Relocate Inc.</span>
                      <span className="designation"><a href="https://ukrpass.eu/" target="_blank" rel='noreferrer noopener'>www.ukrpass.eu</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-11 h-100 px-3">
                <div className="testimonial-grid">
                  <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/funkswoodenwonders.png" alt="Funk's Wooden Wonders LLC" /></span>
                  <p>“ Edwin und sein Team konnten auf voller Linie überzeugen. Wir konnten uns immer auf alles verlassen und bekamen innovative Lösungen für unseren Online-Store. ”</p>
                  <div className="author-info">
                    <div className="thumb">
                      <img loading="lazy" height="" width="50"src="assets/media/sied/hadi.png" alt="Hadi A. Alsaud"/>
                    </div>
                    <div className="content">
                      <span className="name">Hadi A. Alsaud</span>
                      <span className="designation">Co-Gründer @ Funk's Wooden Wonders LLC</span>
                      <span className="designation"><a href="https://funkswoodenwonders.com/" target="_blank" rel='noreferrer noopener'>www.funkswoodenwonders.com</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-11 h-100 px-3">
                <div className="testimonial-grid">
                  <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/semotomedia.png" alt="semoto media" /></span>
                  <p>“ Wirklich, wirklich gute Zusammenarbeit! ”</p>
                  {/* <p>“ Skutoční špecialisti v svojom obore. Odporúčam každému zažiť túto úroveň profesionality a schopnosti. ”</p> */}
                  <div className="author-info">
                    <div className="thumb">
                      <img loading="lazy" height="" width="50"src="assets/media/sied/ali.png" alt="Ali Adnan"/>
                    </div>
                    <div className="content">
                      <span className="name">Ali Adnan</span>
                      <span className="designation">Co-Gründer @ Semoto Media</span>
                      <span className="designation"><a href="https://semoto-media.com/" target="_blank" rel='noreferrer noopener'>www.semoto-media.com</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-11 h-100 px-3">
                <div className="testimonial-grid">
                  <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/elsys.png" alt="elsyspro" /></span>
                  <p>“ Große Zufriedenheit mit dem Design und der Erstellung der Website, hilfreiche Herangehensweise an die Lösung unserer Anforderungen, großartige Verhandlungen, Kommunikation und ausgezeichnete Ergebnisse der Zusammenarbeit. ”</p>
                  {/* <p>“ Skutoční špecialisti v svojom obore. Odporúčam každému zažiť túto úroveň profesionality a schopnosti. ”</p> */}
                  <div className="author-info">
                    <div className="thumb">
                      <img loading="lazy" height="" width="50"src="assets/media/sied/elsyspro_v.png" alt="Elsyspro"/>
                    </div>
                    <div className="content">
                      <span className="name"></span>
                      <span className="designation">Elsyspro</span>
                      <span className="designation"><a href="https://elsyspro.sk/" target="_blank" rel='noreferrer noopener'>www.elsyspro.sk</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-11 h-100 px-3">
                <div className="testimonial-grid">
                  <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/barborka.png" alt="reštauracia barborka" /></span>
                  <p>“ Die Agentur zeigt ein professionelles und verantwortungsvolles Arbeiten. Wann immer es ein Problem gab, wurde es schnell gelöst. Wir waren froh, mit SIED zusammenzuarbeiten. ”</p>
                  {/* <p>“ Skutoční špecialisti v svojom obore. Odporúčam každému zažiť túto úroveň profesionality a schopnosti. ”</p> */}
                  <div className="author-info">
                    <div className="thumb">
                      <img loading="lazy" height="" width="50"src="assets/media/sied/ali.png" alt="barborka"/>
                    </div>
                    <div className="content">
                      <span className="name"></span>
                      <span className="designation">Restaurant Barborka</span>
                      <span className="designation"><a href="https://restauraciabarborka.sk/" target="_blank" rel='noreferrer noopener'>www.restauraciabarborka.sk</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-11 h-100 px-3">
                <div className="testimonial-grid">
                  <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/amsel1.png" alt="amsel" /></span>
                  <p>“ Die Jungs arbeiten als Team, flexibel, professionell. ”</p>
                  {/* <p>“ Skutoční špecialisti v svojom obore. Odporúčam každému zažiť túto úroveň profesionality a schopnosti. ”</p> */}
                  <div className="author-info">
                    <div className="thumb">
                      <img loading="lazy" height="" width="50"src="assets/media/sied/ali.png" alt="amsel"/>
                    </div>
                    <div className="content">
                      <span className="name"></span>
                      <span className="designation">Amsel</span>
                      <span className="designation"><a href="https://amsel.sk/" target="_blank" rel='noreferrer noopener'>www.amsel.sk</a></span>
                    </div>
                  </div>
                </div>
              </div>
              </Carousel>

            </div>
            <div className="row d-block d-sm-none">
            <div className="col-11 h-100 mx-auto py-3">
                <div className="testimonial-grid ">
                  <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/ukrpass.png" alt="ukrpass" /></span>
                  <p>“ Perfekte Zusammenarbeit – exakt das, was wir erwartet haben. ”</p>
                  <div className="author-info">
                    <div className="thumb">
                      <img loading="lazy" height="" width="50"src="assets/media/sied/davidcantor.png" alt="David Cantor" />
                    </div>
                    <div className="content">
                      <span className="name">David Cantor</span>
                      <span className="designation">Gründer &amp; CEO @ Relocate Inc.</span>
                      <span className="designation"><a href="https://ukrpass.eu/" target="_blank" rel='noreferrer noopener'>www.ukrpass.eu</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-11 h-100 mx-auto py-3">
                <div className="testimonial-grid">
                  <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/funkswoodenwonders.png" alt="Funk's Wooden Wonders LLC" /></span>
                  <p>“ Edwin und sein Team konnten auf voller Linie überzeugen. Wir konnten uns immer auf alles verlassen und bekamen innovative Lösungen für unseren Online-Store. ”</p>
                  <div className="author-info">
                    <div className="thumb">
                      <img loading="lazy" height="" width="50"src="assets/media/sied/hadi.png" alt="Hadi A. Alsaud"/>
                    </div>
                    <div className="content">
                      <span className="name">Hadi A. Alsaud</span>
                      <span className="designation">Co-Gründer @ Funk's Wooden Wonders LLC</span>
                      <span className="designation"><a href="https://funkswoodenwonders.com/" target="_blank" rel='noreferrer noopener'>www.funkswoodenwonders.com</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-11 h-100 mx-auto py-3">
                  <div className="testimonial-grid">
                    <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/elsys.png" alt="elsyspro" /></span>
                    <p>“ Große Zufriedenheit mit dem Design und der Erstellung der Website, hilfreiche Herangehensweise an die Lösung unserer Anforderungen, großartige Verhandlungen, Kommunikation und ausgezeichnete Ergebnisse der Zusammenarbeit. ”</p>
                    {/* <p>“ Skutoční špecialisti v svojom obore. Odporúčam každému zažiť túto úroveň profesionality a schopnosti. ”</p> */}
                    <div className="author-info">
                      <div className="thumb">
                        <img loading="lazy" height="" width="50"src="assets/media/sied/elsyspro_v.png" alt="Elsyspro"/>
                      </div>
                      <div className="content">
                        <span className="name"></span>
                        <span className="designation">Elsyspro</span>
                        <span className="designation"><a href="https://elsyspro.sk/" target="_blank" rel='noreferrer noopener'>www.elsyspro.sk</a></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-11 h-100 mx-auto py-3">
                  <div className="testimonial-grid">
                    <span className="social-media"><img loading="lazy" height="" width="50"src="assets/media/sied/barborka.png" alt="reštauracia barborka" /></span>
                    <p>“ Die Agentur zeigt ein professionelles und verantwortungsvolles Arbeiten. Wann immer es ein Problem gab, wurde es schnell gelöst. Wir waren froh, mit SIED zusammenzuarbeiten. ”</p>
                    {/* <p>“ Skutoční špecialisti v svojom obore. Odporúčam každému zažiť túto úroveň profesionality a schopnosti. ”</p> */}
                    <div className="author-info">
                      <div className="thumb">
                        <img loading="lazy" height="" width="50"src="assets/media/sied/ali.png" alt="barborka"/>
                      </div>
                      <div className="content">
                        <span className="name"></span>
                        <span className="designation">Restaurant Barborka</span>
                        <span className="designation"><a href="https://restauraciabarborka.sk/" target="_blank" rel='noreferrer noopener'>www.restauraciabarborka.sk</a></span>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          {/* <ul className="shape-group-4 list-unstyled">
            <li className="shape-1"><img loading="lazy" height="" width=""src="assets/media/others/bubble-1.png" alt="Bubble" /></li>
          </ul> */}
  </>;
}
