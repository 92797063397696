import React, { Component } from 'react'
import { InlineWidget } from 'react-calendly';
import TagManager from 'react-gtm-module/dist/TagManager';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import sal from 'sal.js';

import Reviews from '../components/Reviews';

class Pricing extends Component {
    constructor(props) {
        super(props);
        this.escFunction = this.escFunction.bind(this);
        this.bodyOverflow = this.bodyOverflow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            active: false,
            product: "",
            isModalActive: false,
            formSucc: true,
            submitButtonText: "Odoslať",
            name: '',
            email: '',
            tel: '',
            msg: '',
        };
    }
    escFunction(event) {
        if (event.key === "Escape") {
          this.setState({active: false, isModalActive: false});
          this.bodyOverflow(false);
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let name = this.state.name;
        let email = this.state.email;
        let tel = this.state.tel;
        let msg = this.state.msg;
        let product = this.state.product;
        const inputs = { name, email, tel, product, msg };
        this.setState({submitButtonText: 'Odosiela sa...'});
        fetch('https://be.siedwebs.sk/api/send-pricing', {
          method: 'POST',
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(inputs)
        }).then((response) => {
            this.setState({submitButtonText: 'Odoslať', active: false, name: '', email: '', tel: '', product: '', msg: ''});
          if(response.ok) {
            this.setState({formSucc: true});
        } else {
            this.setState({formSucc: false});
        }
        })
        fetch('https://be.siedwebs.sk/api/send-pricing-reply', {
          method: 'POST',
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(inputs)
        }).then((response) => {
          if(response.ok) {
            this.setState({formSucc: true});
        } else {
            this.setState({formSucc: false});
        }
            this.setState({isModalActive: true});
            this.bodyOverflow(true);
        })
        // TagManager.initialize({'gtmId': 'GTM-KWNVL8X'})
        // const tagManagerArgs = {
        // dataLayer: {
        //     'js': new Date(), // current date time,
        //     event: 'startProject',
        // }
        // }
        // TagManager.dataLayer(tagManagerArgs);
      }
    
    bodyOverflow(bool) {
        if(bool) {
            document.querySelector("body").style.overflow = 'hidden';
        } else {
            document.querySelector('body').removeAttribute("style");
        }
    }
    

    componentDidMount() {
        sal({
          threshold: 0.1,
          once: true
        });
        document.addEventListener("keydown", this.escFunction, false);

        // TagManager.initialize({'gtmId': 'GTM-KWNVL8X'})
        // const tagManagerArgs = {
        // dataLayer: {
        //     'js': new Date(), // current date time,
        //     event: 'pageview',
        //     page: {
        //         url: "/preise",
        //         title: "preise"
        //     }
        // }
        // }
        // TagManager.dataLayer(tagManagerArgs);
    };

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

    render() {
        return (
            <>
                <Helmet prioritizeSeoTags>
                    <title>SIEDwebs | Unsere Preise</title>
                    <meta name="description" content="Unsere Preise richten sich nach Ihren Bedürfnissen: Wählen Sie zwischen Business-, E-Shop- und Enterprise-Lösungen." />
                    <link rel="canonical" href='/preise' />

                    <meta property="og:url" content="https://siedwebs.de/preise" />
                    <meta property="og:title" content="SIEDwebs | Unsere Preise" />
                    <meta property="og:description" content="Unsere Preise richten sich nach Ihren Bedürfnissen: Wählen Sie zwischen Business-, E-Shop- und Enterprise-Lösungen." />

                    <meta property="twitter:url" content="https://siedwebs.de/preise" />
                    <meta name="twitter:title" content="SIEDwebs | Unsere Preise" />
                    <meta name="twitter:description" content="Unsere Preise richten sich nach Ihren Bedürfnissen: Wählen Sie zwischen Business-, E-Shop- und Enterprise-Lösungen." />
                </Helmet>
                {/*=====================================*/}
                {/*=       Breadcrumb Area Start       =*/}
                {/*=====================================*/}
                <div className="breadcrum-area">
                    <div className="container">
                    <div className="breadcrumb">
                        <ul className="list-unstyled">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li className="active">Preise</li>
                        </ul>
                        <h2 className="title h2">Preise</h2>
                    </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                    <li
                        className="shape shape-1"
                        data-sal="slide-right"
                        data-sal-duration={500}
                        data-sal-delay={100}
                    >
                        <img loading="eager" height="" width=""src="assets/media/others/bubble-9.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-2"
                        data-sal="slide-left"
                        data-sal-duration={500}
                        data-sal-delay={200}
                    >
                        <img loading="eager" height="" width=""src="assets/media/others/bubble-11.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-3"
                        data-sal="slide-up"
                        data-sal-duration={500}
                        data-sal-delay={300}
                    >
                        <img loading="eager" height="" width=""src="assets/media/others/line-4.png" alt="Line" />
                    </li>
                    </ul>
                </div>
                {/*=====================================*/}
                {/*=        Pricing Area Start         =*/}
                {/*=====================================*/}
                
                <section className="section section-padding">
                    
                    <div className="container">
                    <div className="section-heading mb-0 pricing">
                        <span className="subtitle">Auf Ihre Bedürfnisse zugeschnittene Dienstleistungen ...</span>
                        <h1 className="title h2">Wählen Sie ein Paket</h1>
                        <p>Ihre erfolgreiche digitale Zukunft beginnt hier!</p>
                    </div>
                    <div className="row">
                        <div
                        className="col-lg-4 flex"
                        data-sal="slide-up"
                        data-sal-duration={800}
                        data-sal-delay={100}
                        >
                        <div className="pricing-table pricing-borderd">
                            <div className="pricing-header">
                            <h2 className="title h3">Business-<br/>Paket</h2>
                            <span className="subtitle">Um Sie und Ihr Unternehmen professionell zu präsentieren</span>
                            <div className="price-wrap">
                                <div className="yearly-pricing">
                                <span className="amount">ab 1.200€</span>
                                </div>
                            </div>
                            <div className="pricing-btn">
                            <span onClick={() => {this.setState({active: true, product: "Biznis"}); this.bodyOverflow(true)}} className="axil-btn btn-large btn-borderd">
                                Anfrage&nbsp;senden
                                </span>
                            </div>
                            </div>
                            <div className="pricing-body">
                            <ul className="list-unstyled">
                                <li>Landingpage/Webseite</li>
                                <li>Modernes und innovatives Design</li>
                                <li>SEO-berücksichtigende Webseitentexte</li>
                                <li>Zugang zum Dashboard zur Seitenverwaltung</li>
                                <li>Digitaler Katalog</li>
                                <li>Integration bestehender Werbeplattformen</li>
                                <li>Einrichtung der Server-Einstellungen zur Nutzung von E-Mail-Marketing</li>
                                <li>90 Tage kostenloser Support</li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-lg-4 flex"
                        data-sal="slide-up"
                        data-sal-duration={800}
                        data-sal-delay={200}
                        >
                        <div className="pricing-table pricing-borderd">
                            <div className="pricing-header">
                            <h2 className="title h3">Onlineshop-<br/>Paket</h2>
                            <span className="subtitle">Für den Aufbau einer Plattform zum Verkauf Ihrer Produkte und Dienstleistungen</span>
                            <div className="price-wrap">
                                <div className="yearly-pricing">
                                <span className="amount">ab 1.800€</span>
                                </div>
                            </div>
                            <div className="pricing-btn">
                            <span onClick={() => {this.setState({active: true, product: "e-Shop"}); this.bodyOverflow(true)}} className="axil-btn btn-large btn-borderd">
                            Anfrage&nbsp;senden
                                </span>
                            </div>
                            </div>
                            <div className="pricing-body">
                            <ul className="list-unstyled">
                                <li>Alle Punkte aus dem Business-Paket</li>
                                <li className='add'>Einrichtung etwaiger Zahlungsschnittstellen</li>
                                <li className='add'>Konfiguration der Bestellautomatisierungen</li>
                                <li className='add'>Konfiguration der automatischen Rechnungsstellung</li>
                                <li className='add'>Zugang zum Dashboard zur Shop-Verwaltung</li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div
                        className="col-lg-4 flex"
                        data-sal="slide-up"
                        data-sal-duration={800}
                        data-sal-delay={300}
                        >
                        <div className="pricing-table active pricing-borderd">
                            <div className="pricing-header">
                            <h2 className="title h3">Enterprise-<br/>Paket</h2>
                            <span className="subtitle">Für alle Anliegen, die in keine Schablone passen</span>
                            <div className="price-wrap">
                                <div className="yearly-pricing">
                                <span className="amount">&mdash;&mdash;</span>
                                </div>
                            </div>
                            <div className="pricing-btn">
                                <span onClick={() => {this.setState({active: true, product: "Riešenia na Mieru"}); this.bodyOverflow(true)}} className="axil-btn btn-large btn-borderd">
                                Anfrage&nbsp;senden
                                </span>
                            </div>
                            </div>
                            <div className="pricing-body">
                            <p></p>
                            <ul className="list-unstyled">
                                <li className='none'>Manche Projekte erfordern mehr Zeit und eine individuelle Preisgestaltung, die auf die jeweils zugrunde liegende Komplexität ausgerichtet ist: Kontaktieren Sie uns gerne, damit wir Sie und Ihr Projekt kennenlernen können</li>
                              <li>Web-Anwendungen</li>
                              <li>Mobile Anwendungen</li>
                              <li>Web-Integrationen</li>
                              <li>Sicherheitssoftware</li>
                              <li>Automatisierungssoftware</li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    <p align="center">
                    Sollten Sie sich unsicher sein, welches Paket am besten für Sie geeignet ist, bzw. andere Fragen an uns haben, sind wir Ihnen bei der Auswahl gerne behilflich! Senden Sie uns Ihre Anfrage an <a href='mailto:info@siedwebs.com'>info@siedwebs.com</a> und Sie erhalten innerhalb kürzester Zeit eine Antwort.</p>
                    </div>
                    {/* <ul className="shape-group-3 list-unstyled">
                    <li className="shape shape-2">
                        <img loading="lazy" height="" width=""src="assets/media/others/bubble-4.png" alt="shape" />
                    </li>
                    </ul> */}
                </section>

                <section className="section section-padding pt-0">
                    <Reviews />
                </section>

                <div className={`pric-form`}>
                        <div className={`form-div ${this.state.active ? "show" : ""}`}>
                            <div onClick={() => {this.setState({active: false}); this.bodyOverflow(false)}} className={`pric-form-close ${this.state.active ? "show" : ""}`}><button aria-label="Zatvoriť formulár"><i className="fas fa-times" /></button></div>
                            {/* <form onSubmit={this.handleSubmit} className="axil-contact-form">
                            <div className="form-group">
                                        <label for="contact-name">Meno alebo Firma *</label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        name="contact-name"
                                        value={this.state.name}
                                        onChange={(e) => this.setState({
                                            name: e.target.value})}
                                        required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="contact-email">Email *</label>
                                        <input
                                        type="email"
                                        className="form-control"
                                        name="contact-email"
                                        value={this.state.email}
                                        onChange={(e) => this.setState({
                                            email: e.target.value})}
                                        required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="contact-tel">Mobil *</label>
                                        <input
                                        type="tel"
                                        className="form-control"
                                        name="contact-tel"
                                        value={this.state.tel}
                                        onChange={(e) => this.setState({
                                            tel: e.target.value})}
                                        required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label for="contact-company">Plán *</label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        name="contact-company"
                                        readOnly
                                        value={this.state.product}
                                        />
                                    </div>
                                    <div className="form-group mb--40">
                                        <label for="contact-message">Špecifikácie (voliteľné)</label>
                                        <textarea
                                        name="contact-message"
                                        id="contact-message"
                                        className="form-control textarea"
                                        cols={30}
                                        rows={4}
                                        value={this.state.msg}
                                        onChange={(e) => this.setState({
                                            msg: e.target.value})}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button
                                        type="submit"
                                        className="axil-btn btn-fill-primary btn-fluid btn-primary"
                                        name="submit-btn"
                                        aria-label="Odoslať formulár"
                                        >
                                        {this.state.submitButtonText}
                                        </button>
                                    </div>
                            </form> */}
                            <InlineWidget url="https://calendly.com/hannahsied/15-minute-demo-call?hide_gdpr_banner=1&primary_color=acc507" />
                        </div>
                        <div onClick={() => {this.setState({active: false}); this.bodyOverflow(false)}} className={`backdrop fade ${this.state.active ? "show" : ""}`}></div>
                    </div>

                    <div className={`form-modal`}>
                      <div className={`form-modal-body ${this.state.isModalActive ? "show" : ""}`}>
                        {this.state.formSucc ? 
                          <>
                            <i class="fa fa-check succ"></i>
                            <h3>Odoslané!</h3>
                            <p>Ďakujeme Vám za dôveru.</p>
                          </>
                          :
                          <>
                            <i class="fa fa-times fail"></i>
                            <h3 class="text-center">Nastala<br />Chyba!</h3>
                            <p>Prosím skúste znovu...</p>
                          </>
                        }
                        
                        <span onClick={() => {this.setState({isModalActive: false}); this.bodyOverflow(false)}} className='axil-btn btn-fill-primary btn-primary'>Zavrieť</span>
                      </div>
                      <div onClick={() => {this.setState({isModalActive: false}); this.bodyOverflow(false)}} className={`backdrop fade ${this.state.isModalActive ? "show" : ""}`}></div>
                    </div>
                {/*=====================================*/}
                {/*=           Faq Area Start         =*/}
                {/*=====================================*/}
                {/* <section className="section section-padding-equal bg-color-light faq-area">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-xl-4">
                        <div className="section-heading heading-left">
                            <span className="subtitle">Pricing Plan</span>
                            <h3 className="title">Frequently asked questions</h3>
                            <p>
                            Aenean hendrerit laoreet vehicula. Nullam convallis augue at enim
                            gravida pellentesque.
                            </p>
                        </div>
                        </div>
                        <div className="col-lg-7 col-xl-8">
                        <div className="faq-accordion">
                            <div
                            className="accordion"
                            id="accordion"
                            data-sal="slide-up"
                            data-sal-duration={800}
                            data-sal-delay={100}
                            >
                            <div className="accordion-item">
                                <h6 className="accordion-header">
                                <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq1"
                                    aria-expanded="true"
                                    aria-controls="faq1"
                                >
                                    1. Nunc rhoncus magna ut venenatis feugiat.
                                </button>
                                </h6>
                                <div
                                id="faq1"
                                className="accordion-collapse collapse show"
                                data-bs-parent="#accordion"
                                >
                                <div className="accordion-body">
                                    <p>
                                    Vivamus magna est, placerat et dignissim et, elementum
                                    quis lacus. Nulla laoreet pharetra vehicula. Vestibulum
                                    euismod augue ac velit consectetur, ac tincidunt ante
                                    hendrerit. Sed lacinia elementum felis, ut tempus ipsum
                                    blandit non.
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h6 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq2"
                                    aria-expanded="false"
                                    aria-controls="faq2"
                                >
                                    2. Nam interdum ante sapien, quis ullamcorper.
                                </button>
                                </h6>
                                <div
                                id="faq2"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordion"
                                >
                                <div className="accordion-body">
                                    <p>
                                    Vivamus magna est, placerat et dignissim et, elementum
                                    quis lacus. Nulla laoreet pharetra vehicula. Vestibulum
                                    euismod augue ac velit consectetur, ac tincidunt ante
                                    hendrerit. Sed lacinia elementum felis, ut tempus ipsum
                                    blandit non.
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h6 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq3"
                                    aria-expanded="false"
                                    aria-controls="faq3"
                                >
                                    3. Nam interdum ante sapien, quis ullamcorper.
                                </button>
                                </h6>
                                <div
                                id="faq3"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordion"
                                >
                                <div className="accordion-body">
                                    <p>
                                    Vivamus magna est, placerat et dignissim et, elementum
                                    quis lacus. Nulla laoreet pharetra vehicula. Vestibulum
                                    euismod augue ac velit consectetur, ac tincidunt ante
                                    hendrerit. Sed lacinia elementum felis, ut tempus ipsum
                                    blandit non.
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h6 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq4"
                                    aria-expanded="false"
                                    aria-controls="faq4"
                                >
                                    4. Nam interdum ante sapien, quis ullamcorper.
                                </button>
                                </h6>
                                <div
                                id="faq4"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordion"
                                >
                                <div className="accordion-body">
                                    <p>
                                    Vivamus magna est, placerat et dignissim et, elementum
                                    quis lacus. Nulla laoreet pharetra vehicula. Vestibulum
                                    euismod augue ac velit consectetur, ac tincidunt ante
                                    hendrerit. Sed lacinia elementum felis, ut tempus ipsum
                                    blandit non.
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h6 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq5"
                                    aria-expanded="false"
                                    aria-controls="faq5"
                                >
                                    5. Nam interdum ante sapien, quis ullamcorper.
                                </button>
                                </h6>
                                <div
                                id="faq5"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordion"
                                >
                                <div className="accordion-body">
                                    <p>
                                    Vivamus magna est, placerat et dignissim et, elementum
                                    quis lacus. Nulla laoreet pharetra vehicula. Vestibulum
                                    euismod augue ac velit consectetur, ac tincidunt ante
                                    hendrerit. Sed lacinia elementum felis, ut tempus ipsum
                                    blandit non.
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h6 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq6"
                                    aria-expanded="false"
                                    aria-controls="faq6"
                                >
                                    6. Nam interdum ante sapien, quis ullamcorper.
                                </button>
                                </h6>
                                <div
                                id="faq6"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordion"
                                >
                                <div className="accordion-body">
                                    <p>
                                    Vivamus magna est, placerat et dignissim et, elementum
                                    quis lacus. Nulla laoreet pharetra vehicula. Vestibulum
                                    euismod augue ac velit consectetur, ac tincidunt ante
                                    hendrerit. Sed lacinia elementum felis, ut tempus ipsum
                                    blandit non.
                                    </p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <ul className="shape-group-6 list-unstyled">
                    <li
                        className="shape shape-1"
                        data-sal="slide-right"
                        data-sal-duration={800}
                        data-sal-delay={100}
                    >
                        <img loading="lazy" height="" width=""src="assets/media/others/bubble-7.png" alt="Bubble" />
                    </li>
                    <li
                        className="shape shape-2"
                        data-sal="slide-down"
                        data-sal-duration={800}
                        data-sal-delay={100}
                    >
                        <img loading="lazy" height="" width=""src="assets/media/others/line-4.png" alt="line" />
                    </li>
                    <li
                        className="shape shape-4"
                        data-sal="slide-right"
                        data-sal-duration={800}
                        data-sal-delay={100}
                    >
                        <img loading="lazy" height="" width=""src="assets/media/others/poses-lady.png" alt="Poses" />
                    </li>
                    </ul>
                </section> */}
            </>
      )
    }
}

export { Pricing }
